<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { ListByType } from "@/plugins/ajax.js";
import SlotTableItem from "./SlotTableItem.vue";
// import Item from "./ImgBox.vue";

const store = useStore();
const response = ref([]);

/**
 * 取得依類型分類
 */
const getMenuList = computed(() => {
    const source = Object.entries(response.value).filter(([key, value]) => value != null);
    const result = Object.fromEntries(source);
    return result;
});

async function getData() {
    try {
        const { data } = await ListByType({ currency: store.state.currencySelect });
        return data.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
getData().then(array => {
    response.value = array;
});
</script>

<template>
    <slot-table-item v-for="(value, name) in getMenuList" v-bind:key="name" v-bind="{ value, name }" />
</template>
