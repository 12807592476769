export const DataLangSource = [
    {
        "title": "简体中文",
        "flagcode": "cn",
        "short": "zh-CN",
        "locale": "cn"
    },
    {
        "title": "繁體中文",
        "flagcode": "hk",
        "short": "zh-TW",
        "locale": "tw"
    },
    {
        "title": "English",
        "flagcode": "us",
        "short": "en-US",
        "locale": "en"
    },
    {
        "title": "Tiếng Việt",
        "flagcode": "vn",
        "short": "vi-VN",
        "locale": "vi"
    },
    {
        "title": "อักษรไทย",
        "flagcode": "th",
        "short": "th-TH",
        "locale": "th"
    },
    {
        "title": "Bahasa Indonesia",
        "flagcode": "id",
        "short": "id-ID",
        "locale": "id"
    },
    {
        "title": "हिंदी",
        "flagcode": "in",
        "short": "hi-IN",
        "locale": "hi"
    },
    {
        "title": "日本語",
        "flagcode": "jp",
        "short": "ja-JP",
        "locale": "ja"
    },
    {
        "title": "한국어",
        "flagcode": "kr",
        "short": "ko-KR",
        "locale": "ko"
    },
    {
        "title": "Português",
        "flagcode": "pt",
        "short": "pt-BR",
        "locale": "pt"
    },
    {
        "title": "Español",
        "flagcode": "es-AR",
        "short": "es-ES",
        "locale": "es"
    }
];

/**
 * Get current language flag.
 * @param {String} flagcode 
 * @returns Exported image. Return Chinese flag if no language flag existed.
 * @see http://www.flagsarenotlanguages.com 
 */
export const ImportLocaleImage = (flagcode = "us") => {
    try {
        return `/img/lang/${flagcode}.png`;
    } catch (error) {
        return `/img/lang/us.png`;
    }
};


/**
 * Get current language.
 * @param {String} lanshort 
 * @returns 
 */
export const GetLangDatas = (lanshort = "en-US") => {
    const value = DataLangSource.filter( its => its.short === lanshort );
    return value.length > 0 ? value[0] : DataLangSource[0];
};

/**
 * 
 * @param {String} lanshort
 * @returns HTML Language
 */
export const HtmlLocale = (lanshort = "en") => {
    const hans = ["zh-cn", "cn", "zh-hans"];
    const hant = ["zh-tw", "tw", "zh-hant"];
    if( hans.includes(lanshort) ) return "zh-hans";
    if( hant.includes(lanshort) ) return "zh-hant";
    return lanshort;
};
