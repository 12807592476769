<script setup>
import { ref } from "vue";

/**
 * 手機面板開關
 */
const moibleMenu = ref(false);
/**
 * 手機開關面板
 */
const toggleMenu = () => {
    moibleMenu.value = !moibleMenu.value;
};
</script>

<template>
    <div class="header-main-side">
        <div @click="toggleMenu">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
        <div class="menu" v-show="moibleMenu">
            <div @click="toggleMenu"></div>
            <router-link to="/member" class="menu-list member">{{ $t('member') }}</router-link>
        </div>
        <div class="close" @click="toggleMenu" v-show="moibleMenu">
            <img src="/img/icon/cancel.png" alt="close mobile menu" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.header {
    &-main {
        @include flex(row, flex-start, center);
        padding: 0 15%;
        margin: 0 auto;

        &-side {
            display: none;
            flex: 1;
            position: relative;

            .line {
                background: #fff;
                margin-top: 6px;
                width: 24px;
                height: 2px;
            }

            .menu {
                @include flex(column, flex-start, center);
                background: $b-gradient-9;
                padding-top: $base-gap-5;
                width: 100%;
                height: 100vh;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 99;

                &-list {
                    color: #fff;
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                    padding: $base-gap-1;

                    &.about {
                        &::before {
                            @include set-menu-icon('about', 6px, 28px, 28px);
                        }
                    }

                    &.member {
                        &::before {
                            @include set-menu-icon('finger', 6px, 28px, 28px);
                        }
                    }

                    &.fingerprint {
                        &::before {
                            @include set-menu-icon('finger', 6px, 28px, 28px);
                        }
                    }
                }
            }

            .close {
                position: fixed;
                left: 5%;
                top: 3%;
                z-index: 109;

                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}

@include big {
    .header {
        &-main {
            padding: 0;

            &-side {
                display: block;
            }
        }
    }
}
</style>
