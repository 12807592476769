export default {
    namespaced: true,
    state: {
        image: "",
        name: "",
        id: 0,
    },
    getters: {
        actived(state) {
            return state.id !== 0;
        },
    },
    mutations: {
        SET_modal(state, { image, name, id }) {
            state.image = image;
            state.name = name;
            state.id = id;
        },
    },
    actions: {
        set_modal({ commit }, { image, name, id }) {
            commit("SET_modal", { image, name, id });
        },
        reset_modal({ commit }) {
            commit("SET_modal", { image: "", name: "", id: 0 });
        },
    },
};
