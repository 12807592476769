import axios from "axios";
import GetBaseURL from "./url-redirect.js";
import { GetCode } from "./params.js";

const baseURL = GetBaseURL(
    window.location,
    window.EMERGENCY_SETTINGS.ENABLE_EMERGENCY_SETTINGS,
    window.EMERGENCY_SETTINGS.EMERGENCY_URL,
);

const instance = axios.create({
    baseURL,
    headers: {
        "Authorization": GetCode(),
    },
});

export const AjaxPostCreateAccount = data => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "POST",
            url: "/v1.0/member/create",
            data
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const AjaxGetWallet = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/currency/wallet",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const AjaxGetGamelink = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/game/link",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const PopularList = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/list/hot",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const ListByType = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/list/type",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const currencyList = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/currency/list",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const PlayerWallet = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/currency/wallet",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}

export const RecommendedList = params => {
    return new Promise( (resolve, reject) => {
        instance({
            method: "GET",
            baseURL,
            url: "/v1.0/list/new",
            params,
        }).then( d => resolve(d) ).catch( e => reject(e) );
    })
}
