import { createStore } from 'vuex';
import { DataLangSource } from "@/plugins/i18n-utils.js"
import game_modal from './modules/GameModal';

export default createStore({
    state: {
        dataLang: DataLangSource,
        lan: "en-US",
        fp: "",
        currencySelect: "CNY",
        env: "",
    },
    getters: {
        dataLang(state) {
            return state.dataLang;
        },
        lan(state) {
            return state.lan;
        },
        fp(state) {
            return state.fp;
        },
        currencySelect(state) {
            return state.currencySelect;
        },
        env(state) {
            return state.env;
        },
    },
    mutations: {
        lanMu(state, myLan) {
            state.lan = myLan;
        },
        fpMu(state, id) {
            state.fp = id;
        },
        currencySelectMu(state, coin) {
            state.currencySelect = coin;
        },
        envMu(state, url) {
            state.env = url;
        },
    },
    actions: {
        lanAc(context, myLan) {
            context.commit('lanMu', myLan);
        },
        fpAc(context, id) {
            context.commit('fpMu', id);
        },
        currencySelectAc(context, coin) {
            context.commit('currencySelectMu', coin);
        },
        envAc(context, url) {
            context.commit('envMu', url);
        },
    },
    modules: {
        game_modal
    }
});
