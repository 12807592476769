const urlParams = new URLSearchParams(window.location.search);

/**
 * 取得參數
 * @param {String} name 名字
 * @param {String} defaultValue 預設值
 * @returns 
 */
const GetUrlOrLocalStorage = (name, defaultValue = '') => {
    // 如果網址不為空且不為null則使用網址
    if (urlParams.get(name) !== '' && urlParams.get(name) !== null) {
        return urlParams.get(name);
    }
    // 如果網址為空或 null 則使用 localStorage
    if (localStorage.getItem(name) !== '' && localStorage.getItem(name) !== null) {
        return localStorage.getItem(name);
    }
    // 如果 localStorage 為空或 null 則使用預設值
    return defaultValue;
};

/**
 * 取得廠商圖示
 * 預設廠商圖示 `0`
 * @returns {String}
 */
export function GetIcon() {
    // localStorage legacy
    if (localStorage.getItem('factory') !== '' && localStorage.getItem('factory') !== null) {
        return localStorage.getItem('factory');
    }
    // 如果 localStorage 為空或 null 則使用 0
    return GetUrlOrLocalStorage('icon', 'OD');
}

/**
 * 取得渠道ID
 * 預設渠道 `998`
 * @returns {String}
 */
export function GetChannelID() {
    return GetUrlOrLocalStorage('channelID', '998');
}

/**
 * 取得廠商代碼
 * 預設廠商代碼 207 平台: `9b0ecf86`, 74 平台 `20e2d2f3`
 * @returns {String}
 */
export function GetCode() {
    const GetPlatformIV = ({ ENABLE_EMERGENCY_SETTINGS = false, platformIV = "" }) => {
        return ENABLE_EMERGENCY_SETTINGS ? platformIV : process.env.VUE_APP_VENDOR_CODE;
    }
    return GetUrlOrLocalStorage('code', GetPlatformIV(window.EMERGENCY_SETTINGS));
}

/**
 * 取得語系
 * 預設語系 `en-US` (英語)
 * @returns {String}
 */
export function GetLang() {
    return GetUrlOrLocalStorage('lang', 'en-US');
}
