<script setup>
import { computed, watch } from "vue";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Swal from 'sweetalert2';
import { NavigateTo } from "@/plugins/utils";
import { AjaxPostCreateAccount, AjaxGetGamelink } from "@/plugins/ajax.js";

const { t } = useI18n();
const store = useStore();

const GameModalActived = computed(() => store.getters['game_modal/actived']);

const changeCdn = computed(() => store.state.env.replace('demowebsite', 'cdn'));

const circleimg = computed(() => `${changeCdn.value}/gamesource/game/${store.state.game_modal.id}/${store.getters.lan}/icon_od.png`);

const getGame = async () => {
    const exist = await AjaxPostCreateAccount({
        account: store.state.fp,
        currency: store.state.currencySelect,
    });

    switch (exist.data.status.code) {
        case 0: // 已建立成功
        case 202: // 會員已存在
        case '0': // 已建立成功
        case '202': // 會員已存在
            break;
        case 105:
        case '105':
            // if status code 105 page to warning
            router.push({ path: '/warning' });
            break;
        default:
            Swal.fire({ title: '帳號異常!!', showCancelButton: false });
            return false;
    }


    // 定義 setParamsLang 事件 - 非支援語系使用英文開啟
    const setParamsLang = (gameLang, gameId) => {
        switch (gameLang) {
            case 'pt-BR':
                return [412, 413].includes(gameId) ? 'en-US' : gameLang;
            default:
                return gameLang;
        }
    };

    // 定義 paramsLang 參數
    const paramsLang = setParamsLang(store.getters.lan, store.state.game_modal.id);

    const gameapi = await AjaxGetGamelink({
        account: store.state.fp,
        gameID: store.state.game_modal.id,
        lang: paramsLang,
        currency: store.state.currencySelect,
        redirectUrl: location.href,
        app: false,
        gamePlat: 1,
    });

    if (gameapi.data.status.code === 0) {
        NavigateTo(gameapi.data.data.url);
    } else if (gameapi.data.status.code === 105) {
        router.push({ path: '/warning' });
    } else {
        Swal.fire({ title: `traceCode : ${gameapi.data.status.traceCode}`, showCancelButton: false });
        store.dispatch('game_modal/reset_modal');
        // mywin.close();
        // console.log(`traceCode : ${res.data.status.traceCode}`);
        // alert(`錯誤代碼 : ${res.data.status.code}
        //        錯誤訊息 : ${res.data.status.message}
        //        追蹤訊息 : ${res.data.status.traceCode}`)
    }
};

const handleImageError = () => {
    let img = new Image();

    img.onload = function () {
        document.querySelector("img.swal2-image").src = `${changeCdn.value}/gamesource/game/${store.state.game_modal.id}/en-US/icon_od.png`;
        img = null;
    };

    img.onerror = () => {
        document.querySelector("img.swal2-image").src = '/img/icon/maintaince-recommend.png';
        img = null;
    };

    img.src = `${changeCdn.value}/gamesource/game/${store.state.game_modal.id}/en-US/icon_od.png`;
};

watch(GameModalActived, (newval) => {
    if (newval === true) {
        Swal.fire({
            showConfirmButton: false,
            didClose() {
                store.dispatch('game_modal/reset_modal');
            },
            imageUrl: circleimg.value,
            imageAlt: `Game ID: ${store.state.game_modal.id}`,
            customClass: {
                // confirmButton: 'btn btn-success',
                // cancelButton: 'btn btn-danger'
                // image: "push-image",
                image: 'image-centre',
                popup: 'min-image-height',
            },
            // style="display: inline-block;"
            html: `
                    <div id="the-playing-modal">                        
                        <button id="play-game-button" type="button" class="swal2-confirm swal2-styled absolute-button" aria-label="Play">
                            ${t('try')}
                        </button>
                    </div>
                  `,
        });
        document.querySelector('#the-playing-modal #play-game-button').onclick = getGame;
        document.querySelector('img.swal2-image').onerror = handleImageError;
    }
});
</script>

<template>
    <div class="game-modal"></div>
</template>

<style lang="scss">
.min-image-height {
    min-height: 216px;
}

.image-centre {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
}

.push-image {
    transform: translateY(-75%);
}

.absolute-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 98%;
}
</style>
