<script setup>
import { ref, computed } from "vue";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { GetLangDatas, ImportLocaleImage, HtmlLocale } from "@/plugins/i18n-utils.js";

// States
const { locale } = useI18n();
const store = useStore();
const isOpen = ref(false);
const baseLang = ref('');
const baseImg = ref('');

// Computed
/**
 * Get language datas
 */
const dataLang = computed(() => {
    return store.getters.dataLang;
});

// Methods
/**
 * Set language menu flag
 */
const langbtn = () => {
    isOpen.value = !isOpen.value;
};
/**
 * Language selective action
 */
const setLocale = (newLocale = { title: '', flag: '', short: '' }) => {
    baseLang.value = newLocale.title;
    baseImg.value = ImportLocaleImage(newLocale.flagcode);
    locale.value = GetLangDatas(newLocale.short).locale;
    store.dispatch('lanAc', newLocale.short);
    localStorage.setItem('lang', newLocale.short);
    document.documentElement.lang = HtmlLocale(newLocale.locale);
    // Currencly reload is not needed
    // location.reload();
};
const initedLocale = GetLangDatas(store.getters.lan);
setLocale(initedLocale);
</script>

<template>
    <div class="header-main-lang">
        <div class="showlang" @click="langbtn">
            <img :src="baseImg" :alt="baseLang" />
            {{ baseLang }}
            <ul :class="['showlang-langs', { open: isOpen }]">
                <li v-for="(item) in dataLang" :key="item" @click="setLocale(item)">
                    <img :src="`/img/lang/${item.flagcode}.png`" :alt="item.flagcode" />
                    {{ item.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.header {
    &-main {
        &-lang {
            @include flex(row, flex-end, flex-start);
            flex: 1;

            .showlang {
                @include flex(row, flex-start, center);
                background: $w-gradient-1;
                border-radius: $su-radius;
                color: $gray-color-1;
                font-size: 18px;
                padding: 0.5rem $base-gap-1;
                min-width: 135px;
                position: relative;
                cursor: pointer;

                img {
                    display: block;
                    margin-right: 6px;
                    width: 20px;
                    height: 20px;
                }

                &-langs {
                    background: $gray-color-4;
                    border-radius: $m-radius;
                    color: #fff;
                    text-align: center;
                    display: none;
                    width: 100%;
                    position: absolute;
                    top: 150%;
                    left: 0;

                    &.open {
                        display: block !important;
                    }

                    li {
                        @include flex(row, flex-start, center);
                        padding: calc($base-gap-1 / 2);
                        cursor: pointer;

                        &:hover {
                            background: $w-gradient-3;
                        }
                    }
                }
            }
        }
    }
}

@include small {
    .header {
        &-main {
            &-lang {
                .showlang {
                    font-size: 16px;
                    justify-content: center;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
</style>
