/**
 * 取出相同陣列
 * @param {Array} arr1 陣列A
 * @param {Array} arr2 陣列B
 * @returns {Array} 相同陣列
 */
function getArrEqual(arr1, arr2) {
    let newArr1 = [];
    for (let i = 0; i < arr2.length; i++) {
        for (let j = 0; j < arr1.length; j++) {
            if (arr1[j] === arr2[i]) {
                newArr1.push(arr1[j]);
            }
        }
    }
    return newArr1;
}

/**
 * 
 * @param {String} href 
 * @param {String} i18needed 
 */
const NavigateTo = (href, i18needed) => {
    const generate_url = (href, other_params = { lang: "en" }) => {
        const url = new URL( href );
        let params = new URLSearchParams({
            ...Object.fromEntries(new URLSearchParams(url.search)),
            ...other_params
        });
        // https://link.victor-win.site/game/va.php
        return `${url.origin}${url.pathname}?${params.toString()}`;
    };
    const a = document.createElement('a');
    const other_params = i18needed ?  { lang: this.lang } : {};
    a.target = "_blank";
    a.href = generate_url(href, other_params);
    document.body.appendChild(a);
    setTimeout(function() {
        a.click();
        document.body.removeChild(a);
    }, 500);
};

export { getArrEqual, NavigateTo };
