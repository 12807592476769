<script setup>
import { useStore } from 'vuex';
import { ref } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import GetBaseURL from './plugins/url-redirect.js';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { GetChannelID, GetCode, GetIcon, GetLang } from "./plugins/params.js";
import { AjaxPostCreateAccount } from "./plugins/ajax.js";
import { GetLangDatas, HtmlLocale } from "@/plugins/i18n-utils.js"

// vuex
const store = useStore();

// i18n
const { locale } = useI18n();
const lanValue = GetLang();
const vuexLang = GetLangDatas(lanValue).locale;

// 語系設定： vuex & localStorage
store.dispatch('lanAc', lanValue);
localStorage.setItem('lang', lanValue);
/**
 * 將 lan value 的值帶入取得目前語系。
 * 使用 function 的理由，主要是避免切換或重質整頁面時，沒有讀到的潛在問題。
 */
const setSystemLocale = (systemLanguage) => {
    locale.value = systemLanguage;
};
setSystemLocale(vuexLang);
document.documentElement.lang = HtmlLocale(vuexLang);

/**
 * 取得幣別
 */
const getCurrency = () => {
    if (localStorage.getItem('currency') != null) {
        // There are some fucking quotation mark exist in older version.
        // To clean them, we must replace it.
        return localStorage.getItem('currency').replace(/\"/g, "");
    }
    return 'CNY';
};
const the_currency = getCurrency();
localStorage.setItem('currency', the_currency);
store.dispatch('currencySelectAc', the_currency);

// 取得廠商並記錄
localStorage.setItem('icon', GetIcon());

// 取得網址的code廠商代碼
const code = ref('');
const channelID = ref('');

// channelID
channelID.value = GetChannelID();
localStorage.setItem('channelID', channelID.value);

// code
code.value = GetCode();
localStorage.setItem('code', code.value);

// 線路設定
const demowebsite = GetBaseURL(window.location);
console.log('使用線路', demowebsite);

// 將網域存取到 vuex
store.dispatch('envAc', demowebsite);

const urlParams = new URLSearchParams(window.location.search);
const getAccount = async (account) => {
    if (account !== null && account !== undefined) {
        return Promise.resolve(account);
    }
    if (localStorage.getItem("account") != null) {
        return Promise.resolve(localStorage.getItem("account"));
    }
    return new Promise((resolve, reject) => {
        const fpPromise = FingerprintJS.load();
        fpPromise.then(fp => fp.get()).then(result => {
            return resolve(result.visitorId);
        }).catch(e => reject(e));
    });
};
const createMember = async () => {
    //創帳號
    const accountAjax = await AjaxPostCreateAccount({
        // code: code.value,
        // channelID: channelID.value,
        account: store.state.fp,
        currency: store.state.currencySelect,
    });
    const get_code = (code = 100) => code;
    switch (get_code(accountAjax.data.status?.code)) {
        case 0:
        case 202:
        case '0':
        case '202':
            return;
        case 104:
        case '104':
            router.push({ path: '/Maintenance' });
            return;
        case 105:
        case '105':
            router.push({ path: '/warning' });
            return;
        default:
            // Error occurred
            console.error(accountAjax.data);
            return;
    }
};
getAccount(urlParams.get("account")).then(account => {
    //創建會員
    store.dispatch('fpAc', account);
    localStorage.setItem('account', account);
    createMember();
}).catch((e) => {
    console.error({ title: '未取得指紋!!', e });
});
</script>

<template>
    <router-view />
</template>

<style lang="scss">
@import '@/assets/scss/_reset.scss';
</style>