import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/Index.vue';

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
    },
    {
        path: '/member',
        name: 'Member',
        component: () => import(/* webpackChunkName: "Member" */ '../views/Member.vue'),
    },
    //ip位置目前不在所屬區域
    {
        path: '/warning',
        name: 'Warning',
        component: () => import(/* webpackChunkName: "Warning" */ '../views/Warning.vue'),
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import(/* webpackChunkName: "Maintenance" */ '../views/Maintenance.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
