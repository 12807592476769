import { createI18n } from "vue-i18n"
// 載入語言
import cn from "@/lang/cn.json"
import en from "@/lang/en.json"
import hi from "@/lang/hi.json"
import ja from "@/lang/ja.json"
import ko from "@/lang/ko.json"
import th from "@/lang/th.json"
import tw from "@/lang/tw.json"
import vi from "@/lang/vi.json"
import id from "@/lang/id.json"
import pt from "@/lang/pt.json"
import es from "@/lang/es.json"

const i18n = createI18n({
    locale: "en", // 設定語言
    fallbackLocale: "en", // 若選擇的語言缺少翻譯則退回的語言
    messages: {
        cn,
        en,
        id,
        hi,
        ja,
        ko,
        th,
        tw,
        vi,
        pt,
        es
    },
})

export default i18n
