<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    item: {
        type: [Object, Number],
        default: () => 0
    },
    rank: {
        type: [Number],
        default: () => 1
    }
});

const store = useStore();
// Demo image modules
const demoimg = ref(null);

// i18n modules
const lan = computed(() => store.getters.lan);

const changeCdn = computed(() => process.env.VUE_APP_CDN_DOMAIN);
const src = computed(() => `${changeCdn.value}/gamesource/game/${props.item.gameID}/${lan.value}/icon_od.png`);

const Name = computed(() => props.item.gameName[lan.value]);

const handleError = () => {
    demoimg.value.src = `${changeCdn.value}/gamesource/game/${props.item.gameID}/en-US/icon_od.png`;
};

// Link modules
const activeModal = (e) => {
    e.preventDefault();
    store.dispatch('game_modal/set_modal', {
        image: src.value,
        name: Name.value,
        id: props.item.gameID,
    });
};
</script>

<template>
    <a v-on:click="activeModal" class="board-game-item">
        <div class="box" v-bind:data-gameid="item.gameID">
            <div v-if="rank > 0" class="rank">{{ rank }}</div>
            <img ref="demoimg" :src="src" :alt="Name" @error="handleError" />
            <p class="name">{{ Name }}</p>
        </div>
    </a>
</template>

<style lang="scss" scoped>
.board-game-item {
    flex: 1;
    cursor: pointer;

    .box {
        margin: 0 auto;
        width: 90%;
        position: relative;
        cursor: pointer;

        .rank {
            @include flex(row, center, center);
            background: red;
            border-bottom-left-radius: $su-radius;
            border-bottom-right-radius: $su-radius;
            font-weight: bold;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
        }

        img {
            display: block;
            margin: 0 auto;
            width: 100%;
        }

        .name {
            text-align: center;
            word-break: break-all;
            padding: 6px 0;
        }
    }
}

@include big {
    .board-game-item {
        margin-right: 8px;

        .box {
            width: 100px;
        }
    }
}
</style>
