<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { RecommendedList, PopularList } from "@/plugins/ajax.js";
import Item from "./ImgBox.vue";

const props = defineProps({
    boardType: {
        type: [String],
        default: () => 'default'
    }
})

const store = useStore();
const router = useRouter();

const response = ref([]);

async function getData(boardType) {
    try {
        let response;

        if (boardType === 'recommend') {
            // recommend
            response = await RecommendedList({ currency: store.state.currencySelect });
        } else {
            // leaderboard
            response = await PopularList({ currency: store.state.currencySelect });
        }

        if (response.status.code == "105") {
            router.push({ path: "/warning" });
            return;
        }
        return response.data.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}

getData(props.boardType).then((gameDatas) => {
    response.value = gameDatas;
});
</script>

<template>
    <section :id="`${boardType}-board`" class="board">
        <div class="board-title">{{ $t(`${boardType}`) }}</div>
        <ul class="board-game">
            <item v-for="(item, index) in response" :key="item.gameID" :item="item" :rank="boardType === 'recommend' ? 0 : index + 1" />
        </ul>
    </section>
</template>

<style lang="scss" scoped>
.board {
    display: block;
    margin: $base-gap-5 auto;
    color: #fff;
    width: 65%;

    &-title {
        font-weight: 500;
        margin-bottom: $base-gap-1;
    }

    &-game {
        display: flex;
    }
}

@include big {
    .board {
        margin: $base-gap-2 auto 0;
        width: 100%;

        &-title {
            margin-left: $base-gap-1;
        }

        &-game {
            margin-left: 12px;
            overflow-x: scroll;
        }
    }
}
</style>
