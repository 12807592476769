<script setup>
// Components
import TheHeader from '@/components/TheHeader/index.vue';
import Banner from '@/components/Banner/index.vue';
import CommonBoard from '@/components/CommonBoard/index.vue';
import Slottable from '@/components/Slottable/index.vue';
import GameModal from '@/components/GameModal.vue';
// Other things
import app from '../../package.json';
import { useRouter } from 'vue-router';

const version = app.version;
const router = useRouter();

if( window.EMERGENCY_SETTINGS.UNDER_MAINTENANCE === true ) {
    router.push({ name: 'Maintenance' });
}
</script>

<template>
    <TheHeader />
    <Banner />
    <CommonBoard :board-type="'recommend'" />
    <CommonBoard :board-type="'leaderboard'" />
    <Slottable />
    <GameModal />
    <div class="version">v {{ version }}</div>
</template>

<style lang="scss" scoped>
.version {
    color: #aaa;
    text-align: center;
    padding: 0 0 2rem;
}
</style>
