<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Item from "./ImgBox.vue";

const props = defineProps({
    value: {
        type: [Number, Object],
        default: () => 0,
    },
    name: {
        type: [String],
        default: () => "slottable",
    },
});

const store = useStore();

const bannerimg = ref(null);
const lan = computed(() => store.getters.lan);
const changeCdn = computed(() => store.state.env.replace("demowebsite", "cdn"));
const src = computed(() => `${changeCdn.value}/gamesource/demo/gameClassify/${lan.value}/${props.name}.jpg`);
const handleError = () => {
    bannerimg.value.src = `${changeCdn.value}/gamesource/demo/gameClassify/en-US/${props.name}.jpg`;
};
</script>

<template>
    <section :id="name" class="slottable">
        <div class="slottable-title">
            {{ $t(name) }}
        </div>
        <div class="slottable-ad">
            <img ref="bannerimg" :src="src" :alt="name" v-on:error="handleError" />
        </div>
        <ul class="slottable-games">
            <item v-for="item in value" :key="item.gameID" :item="item" />
        </ul>
    </section>
</template>

<style lang="scss" scoped>
.slottable {
    display: block;
    margin: $base-gap-5 auto;
    width: 65%;

    &-title {
        border-bottom: 1px solid $w-gradient-3;
        color: #fff;
        font-size: 24px;
        padding-bottom: $base-gap-1;
        margin-bottom: $base-gap-1;

        &::before {
            @include set-menu-icon('slot', 14px, 24px, 27.5px);
            vertical-align: text-bottom;
        }
    }

    &-ad {
        margin-top: $base-gap-2;

        img {
            width: 100%;
        }
    }

    &-games {
        display: flex;
        flex-wrap: wrap;
    }
}

@include big {
    .slottable {
        width: 95%;
    }
}

@include medium {
    .slottable {
        margin: $base-gap-3 auto;
    }
}
</style>
