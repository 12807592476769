<script setup>
import { ref, computed } from "vue";
import { useStore } from 'vuex';
// swiper
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
// Component
import ImgBox from "./Imgbox.vue";

const store = useStore();
const lan = computed(() => store.getters.lan);
const changeCdn = computed(() => store.state.env.replace('demowebsite', 'cdn'));
const url = computed(() => [
    { pic: `${changeCdn.value}/gamesource/demo/slideshow/${lan.value}/carousels_1.png` },
    { pic: `${changeCdn.value}/gamesource/demo/slideshow/${lan.value}/carousels_2.png` },
    { pic: `${changeCdn.value}/gamesource/demo/slideshow/${lan.value}/carousels_3.png` },
    { pic: `${changeCdn.value}/gamesource/demo/slideshow/${lan.value}/carousels_4.png` },
    { pic: `${changeCdn.value}/gamesource/demo/slideshow/${lan.value}/carousels_5.png` }
]);

const autoplay = ref({ delay: 2500, disableOnInteraction: false, });
const modules = ref([Autoplay, Pagination, Navigation]);
const pagination = ref({ clickable: true, });
</script>

<template>
    <section class="banner">
        <swiper class="mySwiper" navigation v-bind="{ spaceBetween: 30, autoplay, modules, pagination }">
            <swiper-slide v-for="(item, index) in url" :key="index">
                <img-box v-bind="{ item, index }" />
            </swiper-slide>
        </swiper>
    </section>
</template>

<style lang="scss" scoped>
.banner {
    margin-top: 9vh;
    background: $bk-color;
    padding: 0 15%;

    .swiper-slide {
        @include flex(row, center, center);

        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

@include big {
    .banner {
        margin-top: 10vh;
        padding: 0;
    }
}

@include mobile-landscape {
    .banner {
        margin-top: 20vh;
    }
}
</style>
