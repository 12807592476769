<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
    item: {
        type: Object,
        default: () => ({ pic: "" }),
    },
    index: {
        type: Number,
        default: 0,
    },
});

const store = useStore();
const slider = ref(null);
const img = computed(() => ({ src: props.item.pic, alt: props.index + 1 }));

const handleError = () => {
    const cdn = store.state.env.replace("demowebsite", "cdn");
    slider.value.src = `${cdn}/gamesource/demo/slideshow/en-US/carousels_${props.index + 1}.png`;
};
</script>

<template>
    <img ref="slider" v-bind="img" v-on:error="handleError" />
</template>