/**
 * Get current URL and change to `gameclien` by given `location` param. There are two exceptions of such mechanic:
 *
 * 1. If the host is 127.0.0.1, the function returns `https://dev-gameclient.va-gaming.com`.
 * 2. The function returns `EMERGENCY_URL` if `ENABLE_EMERGENCY_SETTINGS` is `true`.
 *
 * http://192.168.50.11:8080/
 *
 * @param {Location} location Should be `window.location`
 * @param {Boolean} ENABLE_EMERGENCY_SETTINGS To enable emergency situation
 * @param {String} EMERGENCY_URL The URL for emergency situation
 * @example
 * // returns `https://gameclient.va-gaming.com` if host is `https://h5game.va-gaming.com`.
 * GetBaseURL(window.location, false, "")
 * // returns `https://dev-gameclient.va-gaming.com` if host is `127.0.0.1`.
 * GetBaseURL(window.location, false, "")
 * // returns `https://example.com/api`.
 * GetBaseURL(window.location, true, "https://example.com/api")
 * @returns {String} An API domain
 */
function GetBaseURL(location = window.location, ENABLE_EMERGENCY_SETTINGS = false, EMERGENCY_URL = 'https://example.com/api') {
    // const { hostname, origin } = location;
    // const is_internal = /192.168/.test(hostname);
    if (ENABLE_EMERGENCY_SETTINGS === true) {
        return EMERGENCY_URL;
    }
    return process.env.VUE_APP_AJAX_DOMAIN;
}

export default GetBaseURL;
