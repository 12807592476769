<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    item: {
        type: [Number, Object],
        default: () => 0,
    },
});

const demoimg = ref(null);
const store = useStore();
const lan = computed(() => store.getters.lan);
const handleError = () => {
    demoimg.value.src = `${changeCdn.value}/gamesource/game/${props.item.gameID}/en-US/icon_od.png`;
};

const changeCdn = computed(() => process.env.VUE_APP_CDN_DOMAIN);

// 取得圖片
const src = computed(() => `${changeCdn.value}/gamesource/game/${props.item.gameID}/${lan.value}/icon_od.png`);
// const src = computed(() => `/img/games/recommend/${lan.value}/recommend${props.item}.png`);

// 取得遊戲名字
const Name = computed(() => props.item.gameName[lan.value]);
const activeModal = (e) => {
    e.preventDefault();
    store.dispatch('game_modal/set_modal', {
        image: src.value,
        name: Name.value,
        id: props.item.gameID,
    });
};
</script>

<template>
    <a v-on:click="activeModal" class="slottable-games-item">
        <div class="box" v-bind:data-gameid="item.gameID">
            <img ref="demoimg" :src="src" :alt="Name" v-on:error="handleError" />
            <p class="name">{{ Name }}</p>
        </div>
    </a>
</template>

<style lang="scss" scoped>
.slottable-games-item {
    padding: calc($base-gap-1 / 2);
    width: calc(100% / 9);
    cursor: pointer;

    .box {
        display: block;
        cursor: pointer;

        &:hover>.name {
            color: #fff;
        }

        img {
            width: 100%;
        }

        .name {
            color: #bdbdbd;
            text-align: center;
            padding: 6px 0;
        }
    }
}

@include big {
    .slottable-games-item {
        padding: calc($base-gap-1 / 5);
        width: calc(100% / 5);
    }
}

@include medium {
    .slottable-games-item {
        width: calc(100% / 3);
    }
}
</style>
